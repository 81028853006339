module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jade's Portfolio","short_name":"Jade's Portfolio","description":"A bit about Jade and her work.","lang":"en","start_url":".","display":"minimal-ui","background_color":"#F7F8F8","theme_color":"#ED9A12","icon":"/opt/build/repo/static/favicons/maskable_icon_x512.png","icons":[{"src":"/opt/build/repo/static/favicons/favicon_x512.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"/opt/build/repo/static/favicons/maskable_icon_x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d28f9f6113a1d880aa8284f51bb9c422"},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-20},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
